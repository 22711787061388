import taverna1 from "../static/photos/taverna/taverna.jpeg";
import taverna2 from "../static/photos/taverna/taverna2.jpeg";
import taverna3 from "../static/photos/taverna/letto_taverna.jpeg";
import taverna4 from "../static/photos/taverna/taverna_cucina.jpeg";
import taverna5 from "../static/photos/taverna/taverna_cucina_2.jpeg";
import taverna6 from "../static/photos/taverna/bagno.jpeg";
import valentina5 from "../static/photos/valentina/valentinacamera3.jpeg";
import valentina6 from "../static/photos/valentina/valentinacucina.jpeg";
import valentina7 from "../static/photos/valentina/valentinasalotto.jpeg";
import valentina9 from "../static/photos/valentina/valentinacucina3.jpeg";

import francesca1 from "../static/photos/francesca/francescasalotto.jpeg";
import francesca2 from "../static/photos/francesca/franescasalotto2.jpeg";
import francesca3 from "../static/photos/francesca/letto_alloggio_@.jpeg";
import francesca4 from "../static/photos/francesca/bagno_alloggio.jpeg";
import francesca5 from "../static/photos/francesca/bagno_alloggio_2.jpeg";

import entrata from "../static/photos/entrata.jpeg";
import esterno from "../static/photos/esterno.jpeg";
import esterno1 from "../static/photos/esterno2.jpeg";
import esterno2 from "../static/photos/esterno3.jpeg";
import interno from "../static/photos/interno.jpeg";
import scala from "../static/photos/scale.jpeg";
import scala1 from "../static/photos/scale1.jpeg";


import camera1 from "../static/photos/villadea/camera1.jpeg";
import camera2 from "../static/photos/villadea/camera2.jpeg";
import camera3 from "../static/photos/villadea/camera3.jpeg";
import esterno7 from "../static/photos/villadea/esterno7.jpeg";
import cucina from "../static/photos/villadea/cucina.jpeg";
import salotto from "../static/photos/villadea/salotto.jpeg";
import salotto1 from "../static/photos/villadea/salotto2.jpeg";
import esterno3 from "../static/photos/villadea/esterno3.jpeg";
import esterno_dea from "../static/photos/villadea/esterno.jpeg";
import esterno_dea1 from "../static/photos/villadea/esterno2.jpeg";
import esterno_dea_notte from "../static/photos/villadea/esternonotte.jpeg";



export const taverna_images = [
  {
    id: 1,
    name: "Angled view",
    src: taverna1,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 2,
    name: "Angled view",
    src: taverna2,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 3,
    name: "Angled view",
    src: taverna3,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 4,
    name: "Angled view",
    src: taverna4,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 5,
    name: "Angled view",
    src: taverna5,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 6,
    name: "Angled view",
    src: taverna6,
    alt: "Angled front view with bag zipped and handles upright.",
  },
];
export const valentina_images = [
  {
    id: 5,
    name: "Angled view",
    src: valentina5,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 6,
    name: "Angled view",
    src: valentina6,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 7,
    name: "Angled view",
    src: valentina7,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 9,
    name: "Angled view",
    src: valentina9,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 10,
    name: "Angled view",
    src: esterno2,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 11,
    name: "Angled view",
    src: esterno,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 8,
    name: "Angled view",
    src: esterno1,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 13,
    name: "Angled view",
    src: interno,
    alt: "Angled front view with bag zipped and handles upright.",
  },
];
export const francesca_images = [
  {
    id: 1,
    name: "Angled view",
    src: francesca1,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 2,
    name: "Angled view",
    src: francesca2,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 3,
    name: "Angled view",
    src: francesca3,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 4,
    name: "Angled view",
    src: francesca4,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 5,
    name: "Angled view",
    src: francesca5,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 6,
    name: "Angled view",
    src: esterno2,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 7,
    name: "Angled view",
    src: esterno,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 12,
    name: "Angled view",
    src: entrata,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 8,
    name: "Angled view",
    src: esterno1,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 9,
    name: "Angled view",
    src: interno,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 10,
    name: "Angled view",
    src: scala,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 11,
    name: "Angled view",
    src: scala1,
    alt: "Angled front view with bag zipped and handles upright.",
  },
];
export const villadea_images = [
  {
    id: 1,
    name: "Angled view",
    src: cucina,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 2,
    name: "Angled view",
    src: camera1,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 3,
    name: "Angled view",
    src: camera2,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 4,
    name: "Angled view",
    src: camera3,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 6,
    name: "Angled view",
    src: salotto1,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 12,
    name: "Angled view",
    src: salotto,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 8,
    name: "Angled view",
    src: esterno_dea,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 15,
    name: "Angled view",
    src: esterno7,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 16,
    name: "Angled view",
    src: esterno3,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 9,
    name: "Angled view",
    src: esterno_dea1,
    alt: "Angled front view with bag zipped and handles upright.",
  },
  {
    id: 10,
    name: "Angled view",
    src: esterno_dea_notte,
    alt: "Angled front view with bag zipped and handles upright.",
  },
];

export const taverna = {
  name: "Taverna",
  price: "€50",
  images: taverna_images,
  description: `
        <p>Alloggio familiare composto da zona giorno attrezzata di cucina e zona notte con 2 o 3 posti letto ed un bagno con doccia. <br> Giardino della casa a disposizione degli ospiti, con possibilita di mangiare e grigliare. <br> 
    Nel paese, a pochi passi, si trovano negozi, pizzerie, ristoranti, bancomat e servizi postali. <br> Treno e bus pubblici nel raggio di 200 metri. <br>
Pista ciclabile dove e possibile percorrere il 50% della valle centrale. <br> Nelle vicinanze piste di sci, piscine, maneggi, campi da golf, luoghi dove praticare rafting e meravigliose escursioni.</p>`,
};
export const valentina = {
  name: "Alloggio Valentina",
  price: "€75",
  images: valentina_images,
  description: `
    <p>Alloggio familiare composto da zona giorno attrezzata di cucina e zona notte con 3 posti letto ed un bagno con doccia. <br> Giardino della casa a disposizione degli ospiti, con possibilita di mangiare e grigliare. <br> 
    Nel paese, a pochi passi, si trovano negozi, pizzerie, ristoranti, bancomat e servizi postali. <br> Treno e bus pubblici nel raggio di 200 metri. <br>
Pista ciclabile dove e possibile percorrere il 50% della valle centrale. <br> Nelle vicinanze piste di sci, piscine, maneggi, campi da golf, luoghi dove praticare rafting e meravigliose escursioni.</p>`,
};
export const francesca = {
  name: "Alloggio Francesca",
  price: "€100",
  images: francesca_images,
  description: `
        <p>Alloggio familiare composto da zona giorno attrezzata di cucina e zona notte con 4 o 5 posti letto ed un bagno con doccia. <br> Giardino della casa a disposizione degli ospiti, con possibilita di mangiare e grigliare. <br> 
    Nel paese, a pochi passi, si trovano negozi, pizzerie, ristoranti, bancomat e servizi postali. <br> Treno e bus pubblici nel raggio di 200 metri. <br>
Pista ciclabile dove e possibile percorrere il 50% della valle centrale. <br> Nelle vicinanze piste di sci, piscine, maneggi, campi da golf, luoghi dove praticare rafting e meravigliose escursioni.</p>`,
};


export const villadea = {
  name: "Villa dea del mare",
  price: "€100",
  images: villadea_images,
  description: `
        <p>Villa familiare composto da zona giorno attrezzata di cucina, zona notte con 6 + 1 posti letto e un bagno con doccia. <br> Giardino della casa a disposizione degli ospiti, con possibilita di mangiare e grigliare. L'esterno offre una piccola piscina con sedie a sdraio e una doccia. <br> 
   Nelle immediate vicinanze sono presenti due ristoranti e numerose fontane pubbliche. <br>
    A San Pietro e ad Avetrana troverete tutti i servizi necessari, quali negozi di alimentari, bancomat, farmacie e poste. <br>
    Dietro la casa comincia una passeggiata che si inoltra nella macchia mediterranea tra gli ulivi. Luogo ideale per vedere il tramonto.`,

};
