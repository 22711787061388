import Footer from "../components/villadea/Footer";
import Contatti from "../components/villadea/Contatti";
import casa from "../static/photos/villadea/esterno.jpeg";
import Informazioni from "../components/villadea/Informazioni";
import Map from "../components/villadea/Map";
import {CheckIcon} from "@heroicons/react/solid";
import beach from "../static/icons/beach.svg";
import tower from "../static/icons/tower.svg";
import dock from "../static/icons/pier.svg";
import castello from "../static/icons/sea.svg";
import dune from "../static/icons/red-sea.svg";
import f from "../static/icons/trullo.svg";

const servizi = [
  {
    name: "Posto auto",
  },
  {
    name: "Ottima posizione",
  },
  {
    name: "Coordialita' eccellente",
  },
  {
    name: "Aria condizionata",
  },
  {
    name: "Info e consigli sulla zona",
  },
  {
    name: "Localita' molto rilassante",
  },
];
const places = [
  {
    name: "Citta'",
    description:
        "Facilmente raggiungibili Lecce e Taranto, due capoluoghi pugliesi ricchi di storia e tradizioni. Lecce, la città del Barocco e dei ricami in pietra, è soprannominata la Firenze del sud. Taranto 'la città dei due mari' per la sua peculiare posizione a cavallo del Mar grande e Mar piccolo.",
    icon: castello,
  },
  {
    name: "Porto Cesareo",
    description:
        "Porto Cesareo è stata riconosciuta dagli studiosi erede dell’antico Portus Sasinaedi epoca latina ed è oggi tra le più note località turistiche estive del Salento. Caratterizzante di Porto Cesareo è una bellissima spiaggia che si estende ininterrotta per 17 chilometri e di fronte la spiaggia del centro abitato si erge dal mare un arcipelago di isolotti, spesso riflesso in un mare calmissimo, cristallino e incontaminato.",
    icon: dock,
  },
  {
    name: "Punta prosciutto",
    description:
        "Punta Prosciutto è caratterizzata da km di spiagge con sabbia bianca e fine e con dune alte fino a 8 metri. La zona è parte della Riserva Naturale Palude del Conte e Duna Costiera, un'area ancora selvaggia: decisamente la meta ideale per chi vuole vivere il mare del Salento nella sua bellezza assoluta",
    icon: beach,
  },
  {
    name: "Alberobello",
    description:
        "È un dedalo di vicoli tortuosi, con più di 1000 trulli, il Rione Monti, il più antico della città, insieme al rione Aia Piccola, dove spiccano Casa Pezzolla, un complesso di 15 trulli comunicanti oggi adibito a Museo del Territorio, e il Trullo Sovrano, che ospita spettacoli e concerti.",
    icon: f,
  },
  {
    name: "Dune di campo marino",
    description:
        "Le Dune di Campomarino sono un posto incantato che regala scenari di rara bellezza tra il verde della macchia mediterranea e il blu intenso del mare  ",
    icon: dune,
  },
  {
    name: "Torre colimena e riserva della salina",
    description:
        "Torre Colimena è una delle torri di avvistamento costiere che si è meglio conservata negli anni: è perfettamente integra e meravigliosamente circondata da piante di fichi d'india. La torre fa parte di un sistema difensivo di torri costiere che furono costruite durante la dominazione spagnola contro i pirati turchi. ",
    icon: tower,
  },
];

export default function VillaDeaDelMarePage() {
  return (
    <div className="bg-orange-site">
      {/*<Navigation/>*/}
      <main>
        <div className="relative">
          <div className="w-full">
            <div className="relative  sm:overflow-hidden">
              <div className="absolute inset-0">
                <img
                  className="h-full w-full object-cover"
                  src={casa}
                  alt="Foto della casa dall'alto"
                />
                <div className="absolute inset-0 bg-orange-200 mix-blend-multiply" />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white font-bold">
                    Villa Dea del mare
                  </span>
                </h1>
                <p className="mt-6 max-w-lg mx-auto text-center font-bold text-xl text-white sm:max-w-3xl">
                  Scopri le meraviglie pugliesi grazie a questa splendida casa
                </p>
                <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                  <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                    <a
                      href="/villadea/house"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-orange-700 bg-snow  sm:px-8"
                    >
                      Informazioni
                    </a>
                    <a
                      href="#contatti"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500"
                    >
                      Contatti
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Informazioni />
        <div className="bg-white">
          <div className="max-w-4xl mx-auto px-4 py-8 sm:px-6 sm:pt-12 sm:pb-24 lg:max-w-7xl lg:pt-16 lg:px-8">
            <h2 className="text-5xl font-extrabold text-center  text-orange-500 tracking-tight">
              I nostri servizi
            </h2>
            <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
              {servizi.map((servizio) => (
                  <div key={servizio.name} className="relative">
                    <dt>
                      <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                      <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{servizio.name}</p>
                    </dt>
                    {/*<dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>*/}
                  </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="max-w-4xl mx-auto px-4 py-8 sm:px-6 sm:pt-12 sm:pb-24 lg:max-w-7xl lg:pt-16 lg:px-8">
            <h2 className="text-5xl font-extrabold text-center  text-orange-500 tracking-tight">
              Luoghi nelle vicinanze
            </h2>
            <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
              {places.map((feature) => (
                  <div key={feature.name}>
                    <div>
                    <span className="flex items-center justify-center">
                      <img
                          className="h-20 w-20 text-snow lg:w-24 lg:h-24"
                          aria-hidden="true"
                          src={feature.icon}
                      />
                    </span>
                    </div>
                    <div className="mt-6 text-center">
                      <h3 className="text-2xl font-medium text-orange-500 font-bold">
                        {feature.name}
                      </h3>
                      <p className="mt-2 text-base text-lg text-black">
                        {feature.description}
                      </p>
                    </div>
                  </div>
              ))}
            </div>
          </div>
        </div>
        <Contatti />
        <Map />
      </main>
      <Footer />
    </div>
  );
}
