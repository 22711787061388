import Navigation from "../components/Navigation";
import Footer from "../components/villamafi/Footer";
import Contatti from "../components/villamafi/Contatti";
import casa from "../static/photos/casa.jpeg"
import castello from "../static/icons/fortress.svg"
import terme from "../static/icons/bath-towel.svg"
import mont from "../static/icons/achievement.svg"
import parco from "../static/icons/deer.svg"
import vallata from "../static/icons/observatory(1).svg"
import aosta from "../static/icons/amphitheatre.svg"
import Informazioni from "../components/villamafi/Informazioni";
import Rooms from "../components/villamafi/Rooms";
import Map from "../components/villamafi/Map";

const features = [
  {
    name: "Castello di Fenis",
    description:
      "Castello della Regina Margherita di Savoia, costruito alla fine del XIX secolo ai piedi del Colle Ranzola. Di particolare interesse sono le cinque torri cuspidate che conferiscono alla struttura il \"suo aspetto da fiaba\".  All'interno, sono da visitare con attenzione il pianterreno ed il piano nobile.",
    icon: castello,
  },
  {
    name: "Osservatorio astronomico",
    description:
      "Per gli amanti della natura in viaggio in Valle d'Aosta, è obbligatoria una sortita in Val Ferret, a nord della conca di Courmayeur",
    icon: vallata,
  },
  {
    name: "Skyline",
    description:
        "Costruito su di una rocca nei pressi della Dora Baltea. Il luogo ospitava una roccaforte, già ai tempi di Teodorico, nel VI secolo d.C., un edificio che fu poi completamente ricostruito dai Savoia nel diciannovesimo secolo. Oggi, dopo lunghi lavori di restauro, la struttura ospita il Museo delle Alpi.",
    icon: mont,
  },
  {
    name: "Parco Nazionale Gran Paradiso",
    description:
      "Tra le tante bellezze naturalistiche della regione, merita sicuramente un posto d'onore il Parco Nazionale Gran Paradiso: istituito nel lontano 1922, il parco è il più antico d'Italia. La sua  vasta superficie -più di settantamila ettari- ospita una grande varietà di specie vegetali ed animali. Su tutti domina, però, lo stambecco, simbolo per antonomasia del Gran Paradiso.  ",
    icon: parco,
  },
  {
    name: "Aosta",
    description:
      "Capoluogo ed indiscussa regina della regione è Aosta, baluardo dell'antica civiltà di Roma. Infatti la città è ricca di resti archeologici che testimoniano il suo glorioso passato, come il teatro, il ponte, l'anfiteatro e soprattutto l'Arco di Augusto. Ma non mancano monumenti più recenti come il romanico Complesso di S.Orso ed il Duomo, fatto costruire a partire dall'XI secolo, simbolo della devozione religiosa della città.",
    icon: aosta,
  },
  {
    name: "Terme di Pré-Saint-Didier",
    description:
        "Le fonti termali di Pré-Saint-Didier erano già note agli antichi Romani; un primo centro termale fu costruito nel XVII secolo, ma la struttura che vediamo oggi fu edificata solo a partire dal diciannovesimo secolo. Le salutari e benefiche acque, che sgorgano nei pressi della famosa Cascata dell'Orrido.",
    icon: terme,
  },
];

export default function VillaMafiPage() {

  // const [selectedLanguage , setSelectedLanguage] = useState(language_obj[0])

  return (
    <div className="bg-snow">
      {/*<Navigation/>*/}
      <main>
        <div className="relative">
          <div className="w-full">
            <div className="relative  sm:overflow-hidden">
              <div className="absolute inset-0">
                <img
                  className="h-full w-full object-cover"
                  src={casa}
                  alt="Foto della casa dall'alto"
                />
                <div className="absolute inset-0 bg-blue-400 mix-blend-multiply" />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white font-bold">Villa Mafi Valle D'Aosta</span>
                </h1>
                <p className="mt-6 max-w-lg mx-auto text-center font-bold text-xl text-white sm:max-w-3xl">
                  Scopri le meraviglie Valdostane grazie a questi splendidi alloggi
                </p>
                <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                  <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                    <a
                      href="/apartments/valentina"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-700 bg-snow  sm:px-8"
                    >
                      Appartamenti
                    </a>
                    <a
                      href="#contatti"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500"
                    >
                      Contatti
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <Rooms/>
        <Informazioni/>
        <div className="bg-white">
          <div className="max-w-4xl mx-auto px-4 py-8 sm:px-6 sm:pt-12 sm:pb-24 lg:max-w-7xl lg:pt-16 lg:px-8">
            <h2 className="text-5xl font-extrabold text-center  text-blue-800 tracking-tight">
              Luoghi nelle vicinanze
            </h2>
            <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name}>
                  <div>
                    <span className="flex items-center justify-center">
                      <img
                        className="h-20 w-20 text-snow lg:w-24 lg:h-24"
                        aria-hidden="true"
                        src={feature.icon}
                      />
                    </span>
                  </div>
                  <div className="mt-6 text-center">
                    <h3 className="text-2xl font-medium text-blue-800 font-bold">
                      {feature.name}
                    </h3>
                    <p className="mt-2 text-base text-lg text-black">
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Contatti/>
        <Map/>
      </main>
      <Footer />
    </div>
  );
}
