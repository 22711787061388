import heart from "../../static/icons/heart.svg"
export default function Footer() {
    return (
        <footer className="bg-orange-300">
            <div className="w-full mx-auto py-8 px-4 sm:px-6 md:flex md:items-center md:justify-center lg:px-8">
                <div className=" md:mt-0 md:order-1 flex justify-center items-center">
                    <p className="text-center text-sm lg:text-lg  inline-flex text-black font-bold">Sviluppato e gestito da  <span className="text-blue-800 text-sm lg:text-lg lg:px-2 px-1"> { " "}
                        <a href="https://walterzaninetti.com">Walter Zaninetti</a> </span> con <img src={heart} className="h-4 w-4 lg:w-6 lg:h-6 ml-2 mt-0.5 " alt="cuore"/></p>
                </div>
            </div>
        </footer>
    )
}
