import foto from "../../static/photos/villadea/spaggia.jpeg"

export default function Informazioni() {
    return (
        <div className="bg-orange-400">
            <section aria-labelledby="features-heading" className="relative">
                <div className="aspect-w-3 aspect-h-2 overflow-hidden sm:aspect-w-5 lg:aspect-none lg:absolute lg:w-1/2 lg:h-full lg:pr-4 xl:pr-16">
                    <img
                        src={foto}
                        alt="Incantevole spiaggia di urmo puglia."
                        className="h-full w-full object-cover lg:h-full lg:w-full"
                    />
                </div>

                <div className="max-w-2xl mx-auto pt-8 pb-24 px-4 sm:pb-20 sm:px-6 lg:max-w-7xl lg:pt-8 lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8">
                    <div className="lg:col-start-2">
                        <h1 className="mt-4 text-4xl font-extrabold text-white text-center tracking-tight">Urmo Belsito</h1>

                        <p className="mt-8 text-xl font-bold text-black leading-8 text-center">
                            A due chilometri da San Pietro in Bevagna, in una piccola frazione del comune di Avetrana, fra gli ulivi e il canto delle cicale, sorge Villa Dea del mare. <br/>
                            Qui potrai godere della freschezza della brezza di mare, in uno spazio adatto a coppie, famiglie, gruppi di amici.
                        </p>
                        <a
                            href="/villadea/house"
                            className="flex items-center justify-center mt-8 w-1/2 mx-auto px-4 py-3 font-bold bg-red-500 rounded-md shadow-sm text-white"
                        >
                            Vai alla casa
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
}
