/* This example requires Tailwind CSS v2.0+ */
import {Fragment} from 'react'
import {Popover, Transition} from '@headlessui/react'
import {
  HomeIcon,
  InformationCircleIcon,
  LightningBoltIcon,
  MenuIcon,
  StarIcon,
  XIcon,
} from '@heroicons/react/outline'
import logo from "../static/icons/mountain.svg"

const solutions = [
  {
    name: 'Homepage',
    href: '/villamafi',
    icon: HomeIcon,
  },
  {
    name: 'Contatti',
    href: '#contatti',
    icon: InformationCircleIcon,
  },
  {
    name: 'Valentina',
    href: '/apartments/valentina',
    icon: StarIcon,
  },
  {
    name: 'Francesca',
    href: '/apartments/francesca',
    icon: LightningBoltIcon,
  },
  { name: 'Taverna',href: '/apartments/taverna', icon: HomeIcon },
]



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navigation() {
  return (
      <Popover className="relative bg-snow z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-between md:space-x-10">
            <div className="flex justify-start ">
              <a href="/villamafi">
                <span className="sr-only">Villa Mafi</span>
                <img
                    className="h-12 w-auto sm:h-16"
                    src={logo}
                    alt=""
                />
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-black hover:text-black hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
              {solutions.map((item) => (
                  <a
                      key={item.name}
                      href={item.href}
                      className="hidden -m-3 p-3 lg:flex items-center items-start rounded-lg hover:bg-blue-100"
                  >
                    <item.icon className="flex-shrink-0 h-8 w-8 text-blue-600" aria-hidden="true" />
                    <div className="ml-4">
                      <p className="text-base font-medium text-black">{item.name}</p>
                    </div>
                  </a>
              ))}
          </div>
        </div>

        <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                        className="h-8 w-auto"
                        src={logo}
                        alt="Workflow"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-black hover:text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    {solutions.map((item) => (
                        <a
                            key={item.name}
                            href={item.href}
                            className="-m-3 p-3 flex items-center rounded-md hover:bg-blue-100"
                        >
                          <item.icon className="flex-shrink-0 h-6 w-6 text-blue-600" aria-hidden="true" />
                          <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                        </a>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
  )
}
