import MafiApartmentLayout from "../components/villamafi/MafiAparmentLayout";
import { useParams } from "react-router-dom";
import Navigation from "../components/Navigation";
import Contatti from "../components/villamafi/Contatti";
import Footer from "../components/villamafi/Footer";
import Map from "../components/villamafi/Map";

export default function MafiApartmentPage(){
    let selectedApartment = useParams();
    return(
        <div>
            <Navigation/>
            <MafiApartmentLayout selectedApartments={selectedApartment}/>
            <Contatti />
            <Map/>
            <Footer />
        </div>
    );
}