/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/outline";

const taverna = ["Ideale per una coppia", "Appartamento freschissimo"];
const francesca = [
  "Vista spettacolare",
  "Quattro posti letto",
  "Ideale per amici e famiglie",
  "Ampio giardino a disposizione",
];
const valentina = [
  "Accesso per persone con disabilità",
  "Ideale per una famiglia",
  "Tre posti letto",
  "Ampio giardino a disposizione",
];

export default function Rooms() {
  return (
    <div className="bg-blue-300 mt-0 pt-2">
      <div className="mt-16 lg:bg-blue-800 pb-12 lg:mt-20 lg:pb-20">
        <div className="relative z-0">
          <div className="absolute inset-0 h-5/6 bg-blue-300 lg:h-2/3" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative lg:grid lg:grid-cols-7">
              <div className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                <a href="/apartments/taverna">
                  <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">
                    <div className="flex-1 flex flex-col">
                      <div className="bg-snow px-6 py-10">
                        <div>
                          <h3
                            className="text-center text-2xl font-medium text-black"
                            id="tier-hobby"
                          >
                            La Taverna
                          </h3>
                          <p className="text-black text-md text-center pt-4 pb-2 text-blue-800 font-bold">
                            A partire da{" "}
                          </p>
                          <div className="mt-1 flex items-center justify-center">
                            <span className="px-3 flex items-start text-6xl tracking-tight text-black">
                              <span className="mt-2 mr-2 text-4xl font-medium">
                                €
                              </span>
                              <span className="font-extrabold">50</span>
                            </span>
                            <span className="text-xl font-medium text-black">
                              / giorno
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-blue-50 sm:p-10 lg:p-6 xl:p-10">
                        <ul className="space-y-4">
                          {taverna.map((feature) => (
                            <li key={feature} className="flex items-start">
                              <div className="flex-shrink-0">
                                <CheckIcon
                                  className="flex-shrink-0 h-6 w-6 text-green-500"
                                  aria-hidden="true"
                                />
                              </div>
                              <p className="ml-3 text-base font-medium text-black">
                                {feature}
                              </p>
                            </li>
                          ))}
                        </ul>
                        <div className="mt-8">
                          <div className="rounded-lg shadow-md">
                            <a
                              href="/apartments/taverna"
                              className="block w-full text-center rounded-lg border border-transparent bg-snow px-6 py-3 text-base font-medium text-blue-800 hover:bg-blue-100"
                              aria-describedby="tier-hobby"
                            >
                              Scopri di piu
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                <a href="/apartments/valentina">
                  <div className="relative z-10 rounded-lg shadow-xl">
                    <div
                      className="pointer-events-none absolute inset-0 rounded-lg border-2 border-blue-600"
                      aria-hidden="true"
                    />
                    <div className="absolute inset-x-0 top-0 transform translate-y-px">
                      <div className="flex justify-center transform -translate-y-1/2">
                        <span className="inline-flex rounded-full bg-blue-800 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">
                          Piu scelto
                        </span>
                      </div>
                    </div>
                    <div className="bg-snow rounded-t-lg px-6 pt-12 pb-10">
                      <div>
                        <h3
                          className="text-center text-3xl font-semibold text-black sm:-mx-6"
                          id="tier-growth"
                        >
                          Casetta Valentina
                        </h3>
                        <p className="text-black text-md text-center pt-4 pb-2 text-blue-800 font-bold">
                          A partire da{" "}
                        </p>
                        <div className="mt-1 flex items-center justify-center">
                          <span className="px-3 flex items-start text-6xl tracking-tight text-black sm:text-6xl">
                            <span className="mt-2 mr-2 text-4xl font-medium">
                              €
                            </span>
                            <span className="font-extrabold">75</span>
                          </span>
                          <span className="text-2xl font-medium text-black">
                            / giorno
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-blue-50 sm:px-10 sm:py-10">
                      <ul className="space-y-4">
                        {valentina.map((feature) => (
                          <li key={feature} className="flex items-start">
                            <div className="flex-shrink-0">
                              <CheckIcon
                                className="flex-shrink-0 h-6 w-6 text-green-500"
                                aria-hidden="true"
                              />
                            </div>
                            <p className="ml-3 text-base font-medium text-black">
                              {feature}
                            </p>
                          </li>
                        ))}
                      </ul>
                      <div className="mt-10">
                        <div className="rounded-lg shadow-md">
                          <a
                            href="/apartments/valentina"
                            className="block w-full text-center rounded-lg border border-transparent bg-blue-800 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-blue-700"
                            aria-describedby="tier-growth"
                          >
                            Scopri di piu
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                <a href="/apartments/francesca">
                  <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-lg">
                    <div className="flex-1 flex flex-col">
                      <div className="bg-snow px-6 py-10">
                        <div>
                          <h3
                            className="text-center text-2xl font-medium text-black"
                            id="tier-scale"
                          >
                            Casetta Francesca
                          </h3>
                          <p className="text-black text-md text-center pt-4 pb-2 text-blue-800 font-bold">
                            A partire da{" "}
                          </p>
                          <div className="mt-1 flex items-center justify-center">
                            <span className="px-3 flex items-start text-6xl tracking-tight text-black">
                              <span className="mt-2 mr-2 text-4xl font-medium">
                                €
                              </span>
                              <span className="font-extrabold">100</span>
                            </span>
                            <span className="text-xl font-medium text-black">
                              / giorno
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-blue-50 sm:p-10 lg:p-6 xl:p-10">
                        <ul role="list" className="space-y-4">
                          {francesca.map((feature) => (
                            <li key={feature} className="flex items-start">
                              <div className="flex-shrink-0">
                                <CheckIcon
                                  className="flex-shrink-0 h-6 w-6 text-green-500"
                                  aria-hidden="true"
                                />
                              </div>
                              <p className="ml-3 text-base font-medium text-black">
                                {feature}
                              </p>
                            </li>
                          ))}
                        </ul>
                        <div className="mt-8">
                          <div className="rounded-lg shadow-md">
                            <a
                              href="/apartments/francesca"
                              className="block w-full text-center rounded-lg border border-transparent bg-snow px-6 py-3 text-base font-medium text-blue-800 hover:bg-blue-100"
                              aria-describedby="tier-scale"
                            >
                              Scopri di piu
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
