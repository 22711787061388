import sunrise from "../static/icons/sunrise.svg";
import mont from "../static/icons/mountain.svg";
import casa from "../static/photos/valle.webp";
import spiaggia from "../static/photos/spiaggia.jpg";


export default function SelectApartment (){
    return(
        <div className="flex flex-col h-full relative items-center">
            <div className="h-full w-full flex items-center relative cursor-pointer">
                <a href="/villamafi" className="w-full z-50">
                    <div className="max-w-2xl mx-auto text-center py-8 px-4 sm:py-20 sm:px-6 lg:px-8">
                        <img src={mont} className="w-20 h-20 mx-auto my-3" alt=""/>
                        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                            <span className="block my-1">Villa Mafi</span>
                            <span className="block my-1 text-2xl">Nus - Aosta </span>
                        </h2>
                        <p className="mt-4 text-lg leading-6 text-white font-bold">
                            Splendidi appartamenti comodi per scoprire le meraviglie Valdostane
                        </p>
                    </div>
                </a>
                <div className="absolute inset-0">
                    <img
                        className="h-full w-full object-cover"
                        src={casa}
                        alt="Foto della casa dall'alto"
                    />
                </div>
                <div className="absolute inset-0 bg-gradient-to-r from-blue-100 to-blue-200 mix-blend-multiply" />
            </div>
            <div className="h-full w-full flex bg-red-600 items-center relative cursor-pointer">
                <a href="/villadea" className="w-full z-50">
                    <div className="max-w-2xl mx-auto text-center py-8 px-4 sm:py-20 sm:px-6 lg:px-8">
                        <img src={sunrise} className="w-20 h-20 mx-auto my-3" alt=""/>
                        <h2 className="text-3xl font-extrabold text-black sm:text-4xl">
                            <span className="block my-1">Villa dea del mare Salento</span>
                            <span className="block my-1 text-2xl">Urmo - Taranto</span>
                        </h2>
                        <p className="mt-4 text-lg leading-6 text-black font-bold">
                            Villa indipendente situata in uno dei luoghi migliori d'Italia
                        </p>
                    </div>
                </a>
                <div className="absolute inset-0">
                    <img
                        className="h-full w-full object-cover"
                        src={spiaggia}
                        alt="Foto della casa dall'alto"
                    />
                </div>
            </div>
        </div>
    );
}