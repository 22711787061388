import React from 'react'
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';

const containerStyle = {
    width: '100',
    height: '400px'
};

export default function Map() {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCQybwuiqn9LuHGAyMuRwNnPpRHwAy-FH8"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        map.setCenter(center);
        map.setZoom(15)
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const center = {
        lat: 40.324327,
        lng:17.698542
    };

    return isLoaded ? (
        <div className="w-full" style={{width: '100%', height: 400}}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                <Marker
                    icon={"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"}
                    position={center}
                />
                <></>
            </GoogleMap>
        </div>
    ) : <></>
}