import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import VillaMafiPage from "./pages/VillaMafiPage";
import "./style/index.css";
import PageNotFound from "./pages/PageNotFound";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SelectApartment from "./pages/SelectApartment";
import MafiApartmentPage from "./pages/MafiApartmentPage";
import VillaDeaDelMarePage from "./pages/VillaDeaDelMarePage";
import VillaDeaApartmentLayout from "./components/villadea/VillaDeaAparmentLayout";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router>
        <Routes>
            <Route path="/" element={<SelectApartment/>} />
            <Route path="/villamafi" element={<VillaMafiPage/>} />
            <Route path="/villadea" element={<VillaDeaDelMarePage/>} />
            <Route path="/apartments/:selectedApartment" element={<MafiApartmentPage/>} />
            <Route path="/villadea/house" element={<VillaDeaApartmentLayout/>} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    </Router>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
