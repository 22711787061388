import {Tab} from "@headlessui/react";
import {francesca, taverna, valentina} from "../../texts/GalleryVariables";
import parking from "../../static/icons/parking-sign.svg"
import wifi from "../../static/icons/wifi-sign.svg"
import towel from "../../static/icons/towels.svg"
import bici from "../../static/icons/mountain-bike.svg"

const policies = [
  {
    name: "Asciugamani e lenzuola",
    icon: towel,
  },
  {
    name: "Wifi gratuito",
    icon: wifi,
  },
  {
    name: "Comodo parcheggio nelle vicinanze",
    icon: parking,
  },
  {
    name: "Noleggio biciclette",
    icon: bici,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MafiApartmentLayout({ selectedApartments }) {

  function setInfoObject(){
    switch(selectedApartments.selectedApartment){
      case "francesca" : return francesca;
      case "taverna" : return taverna;
      case "valentina" : return valentina;
      default : return {}
    }
  }

  const info_object = setInfoObject();

  return (
    <div className="bg-snow">
      <div className="max-w-2xl mx-auto pb-6 px-2 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
          {/* Image gallery */}
          <Tab.Group as="div" className="flex flex-col-reverse">
            {/* Image selector */}
            <div className=" mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
              <Tab.List className="grid grid-cols-4 gap-6">
                {info_object.images.map((image) => (
                  <Tab
                    key={image.id}
                    className="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-black cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                  >
                    {({ selected }) => (
                      <>
                        <span className="sr-only">{image.name}</span>
                        <span className="absolute inset-0 rounded-md overflow-hidden">
                          <img
                            src={image.src}
                            alt={image.alt}
                            className="w-full h-full object-center object-cover"
                          />
                        </span>
                        <span
                          className={classNames(
                            selected ? "ring-indigo-500" : "ring-transparent",
                            "absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none"
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>

            <Tab.Panels className="w-full aspect-w-1 aspect-h-1">
              {info_object.images.map((image) => (
                <Tab.Panel key={image.id}>
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="w-full h-full object-center object-cover sm:rounded-lg"
                  />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>

          {/* Product info */}
          <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
            <h1 className="text-4xl font-extrabold tracking-tight text-blue-800 text-center md:text-left">
              {info_object.name}
            </h1>

            <div className="mt-3">
              <h2 className="sr-only">Informazioni alloggio</h2>
              <p className="text-3xl font-bold text-black text-center md:text-left">
                Da{" "}
                <span className="text-3xl font-bold text-blue-800 text-center md:text-left">
                  {" "}
                  {info_object.price}
                </span>{" "}
                al giorno
              </p>
            </div>

            <div className="mt-6">
              <h3 className="sr-only">Descrizione</h3>

              <div
                className="text-base text-black space-y-6 text-center md:text-left"
                dangerouslySetInnerHTML={{ __html: info_object.description }}
              />
            </div>

            <div className="mt-10 flex sm:flex-col1 justify-center">
              <a href="src/components/villamafi/MafiAparmentLayout#contatti">
                <button
                  type="button"
                  className="max-w-xs flex-1 bg-blue-500 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:w-full"
                >
                  Contattaci
                </button>
              </a>
            </div>

            <section aria-labelledby="policies-heading" className="mt-10">
              <h2 id="policies-heading" className="sr-only">
                Our Policies
              </h2>

              <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
                {policies.map((policy) => (
                  <div
                    key={policy.name}
                    className="bg-blue-500 rounded-lg p-3 text-center"
                  >
                    <dt>
                      <img
                        className="mx-auto h-12 w-12 flex-shrink-0 text-black my-2"
                        aria-hidden="true"
                        src={policy.icon}
                       alt={"icone"}/>
                      <span className="mt-4 text-sm font-bold text-black">
                        {policy.name}
                      </span>
                    </dt>
                  </div>
                ))}
              </dl>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
